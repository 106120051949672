import { IconButton } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { MdClear } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Header, Icon } from 'semantic-ui-react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AvatarImage from '../../../app/common/image/AvatarImage';
import { CardComment } from '../../../app/models/cardComment';
import { CommentAttachment as CommentAttachmentModel } from '../../../app/models/commentAttachment';
import { useStore } from '../../../app/stores/store';
import CommentAttachment from './CommentAttachment';

//#region Styling
const CommentFrame = styled.div`
    flex-grow:1;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
    margin: 0 4px 0 10px;
    overflow: hidden;
    position: relative;
    transition: box-shadow 85ms ease;
`;

const CommentBox = styled.div`
    padding: 8px 12px;
    position: relative;
    transition-duration: 85ms;
    transition-property: padding-bottom;
    transition-timing-function: ease;
`;

const StyleTextArea = styled(TextareaAutosize)`
    background: #fff!important;
    box-shadow: none;
    box-sizing: initial;
    height: 20px;
    margin: 0;
    min-height: 20px;
    padding: 0;
    resize: none;
    width: 100%;
    border:none;
    outline:none;
    overflow: hidden;
    overflow-wrap: break-word;
    vertical-align: bottom;
`;

const CommentControl = styled.div`
    bottom: 8px;
    left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ComponentWrapper = styled.div`
     && {
            width:100%;
            display: flex;
            padding:8px 0;
     }
`;

const CommentWrapper = styled.div`
     && {
            flex-grow:1;
            width:100%;
            display: flex;
            flex-direction:column;
     }
`;
const Avatar = styled(AvatarImage)`
    background-color: #dfe1e6;
    border-radius: 25em;
    color: #172b4d;
    cursor: pointer;
    display: block;
    float: left;
    height: 32px;
    margin: 0 4px 4px 0;
    overflow: visible;
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    width: 32px;
    z-index: 0;
`;

const ActionContainer = styled.div`
    margin: 4px 0 0 14px;
`;

const ActionLink = styled.span`
    && {
        font-size:12px;
        font-weight: 500;
    }
    &:hover{
        text-shadow:0px 0px 1px black;
        cursor:pointer;
    }

`;

const CommentHeaderContainer = styled.div`
    margin: 0 0 4px 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
`;



const AdditionalControlWrapper = styled.div`
    &&{

    }
`;


const MainControlWrapper = styled.div`
    &&{

    }
`;

const ControlIcon = styled(Icon)`
    box-shadow:none !important;
    cursor: pointer !important;
    border-radius: 4px !important;
        &:hover{
        background-color: #eee !important;
        }

`;

const AttachmentContainer = styled.div`
    && {
        margin: 8px 0;
    }
`;

const _swal = withReactContent(Swal);

const attachLightBoxIndex = (attachments: CommentAttachmentModel[]) => {
    let count = 0;
    return attachments.map(a => {
        if (a.fileName.match(/.(jpg|jpeg|png)$/i)) {
            let attachment = {
                ...a,
                index: count
            }
            count++;
            return attachment;
        } else {
            return a;
        }
    })
}

interface Props {
    comment: CardComment;
}

//#endregion

const CommentEditInput = ({ comment }: Props) => {
    const { cardCommentStore, commentAttachmentStore, userStore, boardStore } = useStore();
    const { updateComment, deleteAttachment, attachmentToBeDeleted } = commentAttachmentStore;
    const { user, getClientUsedStorage } = userStore;
    const { board } = boardStore;

    const [message, setMessage] = useState(comment.message);
    const [open, setOpen] = useState(false);

    const inputFile = useRef<HTMLInputElement>(null);
    const attachmentIcon = useRef<HTMLElement>(null);
    const [files, setFiles] = useState<any[]>([]);

    const handleSubmit = async () => {
        if (!message.length) {
            toast.error('Comment should not be empty.');
            setMessage(comment.message);
            return;
        }

        if (!board || !board?.workSpace) {
            toast.error('Error in retrieving board instance.');
            return;
        }

        let totalFileSize = 0;

        for (const file of files) {
            totalFileSize += file.size / (1024 * 1024);
        }

        if (files.length > 0) {
            let usedStorage = await getClientUsedStorage(user!.clientId);

            if (Math.round(parseInt(usedStorage!.totalSize)) + Math.round(totalFileSize) > 10000) {
                toast.error('You have reached your 10,000 MB storage capacity for attachment.')
                return;
            }
        } 
        if (attachmentToBeDeleted.length > 0) {
            attachmentToBeDeleted.forEach(i => {
                deleteAttachment(i);
            });
        }
        const response = updateComment(
            comment.cardId,
            comment.id,
            files,
            message,
            board.workSpace.clientId!
        );

        if (files.length)
            toast.promise(response,
                {
                    pending: `Uploading files`,
                    success: `Files uploaded!`,
                    error: `Uploading error!`
                });

        setOpen(false);
        setFiles([]);
        attachmentToBeDeleted.splice(0);
    }

    const handleFileUpload = () => {
        if (inputFile != null && inputFile.current != null)
            inputFile.current.click();
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== "") {
            if (event.target.files != null) {

                //validation
                for (const file of event.target.files as any) {
                    if (file.size > 100000000) {
                        toast.error('File must not exceed 100 MB');
                        return;
                    }
                    file.id = 'files' + files.length;
                    files.push(file);
                }

                //const mergingFiles = files != null ? files : [];
                const mergingFiles = [];
                setFiles([...mergingFiles, ...files])
            }

        } else {
            toast.error('No file Selected');
        }
        //if (event.currentTarget.value !== "") {
        //    if (event.target.files != null) {
        //        const mergingFiles = files != null ? files : [];
        //        setFiles([...mergingFiles, ...Array.from(event.target.files)]);
        //    }

        //} else {
        //    toast.error('No file Selected');
        //}
         event.currentTarget.value = "";
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }

    const handleClose = () => {
        boardStore.hubConnection!.invoke('LoadComments', comment.cardId);
        setMessage(comment.message);
        setOpen(false);
        attachmentToBeDeleted.splice(0)
    }

    const handleOnDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Comment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                cardCommentStore.delete(comment.id);
            }
        });
    };

    const handleDeleteAttachment = (attachmentId: string) => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Attachment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                //deleteAttachment(attachmentId);
                var attachment = comment.attachments.find(x => x.id == attachmentId);
                comment.attachments.splice(comment.attachments.indexOf(attachment!), 1)
                attachmentToBeDeleted.push(attachmentId);
            }
        });
    }

    const removeSelectedFile = (fileName: string) => {
        if (files != null) {
            setFiles(files.filter(f => f.id != fileName));
        }
    }

    useEffect(() => {
        setMessage(comment.message);
    }, [comment.message]);

    return (
        <ComponentWrapper>
            <Avatar userId={comment.userId} hasPhoto={comment.hasProfilePicture!} style={{ width: '40px', height: '40px' }} avatar circular />
            <CommentWrapper>
                <CommentHeaderContainer>
                    <Header as='h4' style={{ margin: '0 12px 0 0' }}>
                        {comment.displayName}
                    </Header>
                    <small>
                        {moment.utc(comment.date).fromNow()} {moment.utc(comment.date!) < moment.utc(comment.dateUpdated) ? '(edited)' : ''}
                    </small>
                </CommentHeaderContainer>
                <CommentFrame>
                    <CommentBox>
                        <StyleTextArea
                            minRows={1}
                            placeholder='Write a comment...'
                            onChange={handleOnChange}
                            value={message}
                            readOnly={!open}
                        />
                        {comment.attachments && (
                            <SimpleReactLightbox>
                                <SRLWrapper>
                                    <AttachmentContainer>
                                        {attachLightBoxIndex(comment.attachments).map((a, index) => (
                                            <CommentAttachment
                                                fileId={a.id}
                                                key={index}
                                                fileName={a.fileName}
                                                lightBoxIndex={a.index!}
                                                isOpen={open}
                                                attachmentId={a.id}
                                                onDelete={handleDeleteAttachment} />))}

                                        {files && open && (
                                            <AttachmentContainer>
                                                {files.map((f, index) => (<CommentAttachment
                                                    key={index}
                                                    lightBoxIndex={index}
                                                    fileName={f.name}
                                                    isOpen={true}
                                                    fileId={f.id}
                                                    onRemove={removeSelectedFile} />))}
                                            </AttachmentContainer>
                                        )}
                                    </AttachmentContainer>
                                </SRLWrapper>
                            </SimpleReactLightbox>
                        )}
                        {open ? (
                            <CommentControl>
                                <MainControlWrapper>
                                    <Button color='blue' compact size='small' onClick={handleSubmit}>
                                        Save
                                    </Button>
                                    <IconButton onClick={handleClose}>
                                        <MdClear />
                                    </IconButton>
                                </MainControlWrapper>
                                <AdditionalControlWrapper>
                                    <input type='file' id='attachment' multiple ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
                                    <ControlIcon ref={attachmentIcon} name='paperclip' bordered onClick={handleFileUpload} />
                                </AdditionalControlWrapper>
                            </CommentControl>) : ''}

                    </CommentBox>
                </CommentFrame>
                {user?.id == comment.userId && !open ? (
                    <ActionContainer>
                        <ActionLink onClick={() => setOpen(true)}>Edit</ActionLink> - <ActionLink onClick={handleOnDelete}>Delete</ActionLink>
                    </ActionContainer>
                ) : ''}
            </CommentWrapper>
        </ComponentWrapper>
    );
}

export default observer(CommentEditInput);