import React, { ChangeEvent, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import AvatarImage from '../../../app/common/image/AvatarImage';
import { useStore } from '../../../app/stores/store';
import CommentAttachment from './CommentAttachment';

//#region Styling

const CommentFrame = styled.div`
    flex-grow:1;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px -1px #091e4240, 0 0 0 1px #091e4214;
    margin: 0 4px 12px 10px;
    overflow: hidden;
    position: relative;
    transition: box-shadow 85ms ease;
`;

const CommentBox = styled.div`
    padding: 8px 12px;
    position: relative;
    transition-duration: 85ms;
    transition-property: padding-bottom;
    transition-timing-function: ease;
`;

const StyleTextArea = styled(TextareaAutosize)`
    background: #fff!important;
    box-shadow: none;
    box-sizing: initial;
    height: 20px;
    margin: 0;
    min-height: 20px;
    padding: 0;
    resize: none;
    width: 100%;
    border:none;
    outline:none;
    overflow: hidden;
    overflow-wrap: break-word;
`;

const CommentControl = styled.div`
    bottom: 8px;
    left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ComponentWrapper = styled.div`
     && {
            width:100%;
            display: flex;
     }
`;
const Avatar = styled(AvatarImage)`
    background-color: #dfe1e6;
    border-radius: 25em;
    color: #172b4d;
    cursor: pointer;
    display: block;
    float: left;
    height: 32px;
    margin: 0 4px 4px 0;
    overflow: visible;
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    width: 32px;
    z-index: 0;
`;

const AdditionalControlWrapper = styled.div`
    &&{

    }
`;

const ControlIcon = styled(Icon)`
    box-shadow:none !important;
    cursor: pointer !important;
    border-radius: 4px !important;
        &:hover{
        background-color: #eee !important;
        }

`;

const AttachmentContainer = styled.div`
    && {
        margin: 8px 0;
    }
`;

//#endregion

interface Props {
    cardId: string;
    comment: string;
}

const CommentInput = ({ cardId, comment }: Props) => {
    const { commentAttachmentStore, cardCommentStore, userStore, boardStore } = useStore();
    const { user, getClientUsedStorage } = userStore;
    const { add } = commentAttachmentStore;
    const { board } = boardStore;

    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const [selecting, setSelecting] = useState(false);
    const inputFile = useRef<HTMLInputElement>(null);
    const attachmentIcon = useRef<HTMLElement>(null);
    const [files, setFiles] = useState<any[]>([]);

    const handleFileUpload = () => {
        setSelecting(true);
        if (inputFile != null && inputFile.current != null)
            inputFile.current.click();
    }

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value !== "") {
            if (event.target.files != null) {

                //validation
                for (const file of event.target.files as any) {
                    if (file.size > 100000000) {
                        toast.error('File must not exceed 100 MB');
                        return;
                    }
                    file.id = 'files' + files.length;
                    files.push(file);
                }

                const mergingFiles = files != null ? files : [];
                /*setFiles([...mergingFiles, ...Array.from(event.target.files)]);*/

            }

        } else {
            toast.error('No file Selected');
        }
        setSelecting(false);
        event.currentTarget.value = "";
    }

    const handleSubmit = async () => {
        if (!message.length) {
            toast.error('Comment should not be empty.');
            return;
        }

        if (!board || !board?.workSpace) {
            toast.error('Error in retrieving board instance.');
            return;
        }

        let totalFileSize = 0;

        for (const file of files) {
            totalFileSize += file.size / (1024 * 1024);
        }
        if (files.length > 0) {
            let usedStorage = await getClientUsedStorage(user!.clientId);

            if (Math.round(parseInt(usedStorage!.totalSize)) + Math.round(totalFileSize) > 10000) {
                toast.error('You have reached your 10,000 MB storage capacity for attachment.')
                return;
            }
        } 
        

        const response = add(cardId,
            files,
            message,
            board?.workSpace?.clientId!
        );

        if (files.length)
            toast.promise(
                response,
                {
                    pending: `Uploading files`,
                    success: `Files uploaded!`,
                    error: `Uploading error!`
                });

        setOpen(false);
        setMessage('');
        setFiles([]);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value);
    }

    const handleBlur = () => {
        if (!message.length && !selecting)
            setOpen(false);
    }

    const removeSelectedFile = (fileName: string) => {
        if (files != null) {
            setFiles(files.filter(f => f.id != fileName));
        }
    }

    return (
        <ComponentWrapper>
            <Avatar userId={user?.id!} hasPhoto={user?.hasProfilePicture!} style={{ width: '40px', height: '40px' }} avatar circular />
            <CommentFrame>
                <CommentBox>
                    <StyleTextArea
                        minRows={2}
                        placeholder='Write a comment...'
                        onClick={() => setOpen(true)}
                        onBlur={handleBlur}
                        onChange={handleOnChange}
                        value={message}
                    />
                    {files && open && (
                        <AttachmentContainer>
                            {files.map((f, index) => (
                                <CommentAttachment
                                    key={index}
                                    fileId={f.id}
                                    fileName={f.name}
                                    isOpen={true}
                                    lightBoxIndex={index}
                                    onRemove={removeSelectedFile} />))}
                        </AttachmentContainer>
                    )}
                    {open ? (
                        <CommentControl>
                            <Button color='blue' compact size='small' onMouseDown={handleSubmit}>
                                Save
                            </Button>
                            <AdditionalControlWrapper>
                                <input type='file' id='attachment' multiple ref={inputFile} onChange={handleFileChange} style={{ display: 'none' }} />
                                <ControlIcon ref={attachmentIcon} name='paperclip' bordered onMouseDown={handleFileUpload} />
                            </AdditionalControlWrapper>
                        </CommentControl>
                    ) : ''}
                </CommentBox>
            </CommentFrame>
        </ComponentWrapper>
    );
}

export default CommentInput;